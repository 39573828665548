import i18n from '@/i18n/i18n'

export const TYPE_INFO = {
  36: {
    typeId: 36,
    classifyId: 25,
    type: 'RA',
    classify: 'speaking',
    h1: 'Read aloud',
    title:
      'Look at the text below. In 40 seconds, you must read this text aloud as naturally and clearly as possible. You have 40 seconds to read aloud.'
  },
  37: {
    typeId: 37,
    classifyId: 25,
    type: 'RS',
    classify: 'speaking',
    h1: 'Repeat sentence',
    title:
      'You will hear a sentence. Please repeat the sentence exactly as you hear it. You will hear the sentence only once.'
  },
  38: {
    typeId: 38,
    classifyId: 25,
    type: 'DI',
    classify: 'speaking',
    h1: 'Describe image',
    title:
      'Look at the graph below. In 25 seconds, please speak into the microphone and describe in detail what the graph is showing. You will have 40 seconds to give your response.'
  },
  39: {
    typeId: 39,
    classifyId: 25,
    type: 'RL',
    classify: 'speaking',
    h1: 'Re-tell lecture',
    title:
      'You will hear a lecture. After listening to the lecture, in 10 seconds, please speak into the microphone and retell what you have just heard from the lecture in your own words. You will have 40 seconds to give your response.'
  },
  40: {
    typeId: 40,
    classifyId: 25,
    type: 'ASQ',
    classify: 'speaking',
    h1: 'Answer short question',
    title:
      'You will hear a question. Please give a simple and short answer. Often just one or a few words is enough.'
  },
  28: {
    typeId: 28,
    classifyId: 24,
    type: 'SST',
    classify: 'listening',
    h1: 'Summarize spoken text',
    title:
      'You will hear a short report. Write a summary for a fellow student who was not present. You should write 50-70 words. You have 10 minutes to finish this task. Your response will be judged on the quality of your writing and on how well your response presents the key points presented in the lecture.'
  },
  29: {
    typeId: 29,
    classifyId: 24,
    type: 'MCM',
    classify: 'listening',
    h1: 'Multiple-choice, choose multiple answers',
    title:
      'Listen to the recording and answer the question by selecting all the correct responses. You will need to select more than one response.'
  },
  30: {
    typeId: 30,
    classifyId: 24,
    type: 'FIB',
    classify: 'listening',
    h1: 'Fill in the blanks',
    title: 'You will hear a recording. Type the missing words in each blank.'
  },
  31: {
    typeId: 31,
    classifyId: 24,
    type: 'HCS',
    classify: 'listening',
    h1: 'Highlight correct summary',
    title:
      'You will hear a recording. Click on the paragraph that best relates to the recording.'
  },
  32: {
    typeId: 32,
    classifyId: 24,
    type: 'MCS',
    classify: 'listening',
    h1: 'Multiple-choice, choose single answer',
    title:
      'Listen to the recording and answer the multiple-choice by selecting the correct responses. Only one response is correct.'
  },
  33: {
    typeId: 33,
    classifyId: 24,
    type: 'SMW',
    classify: 'listening',
    h1: 'Select missing words',
    title:
      'You will hear a recording about fiction writing. At the end of the recording the lost word or group of words has been replaced by a beep. Select the correct option to complete the recording.'
  },
  34: {
    typeId: 34,
    classifyId: 24,
    type: 'HIW',
    classify: 'listening',
    h1: 'Highlight incorrect words',
    title:
      'You will hear a recording. Below is a transcription of the recording. Some words in the transcription differ from what the speaker said. Please click on the words that are different.'
  },
  35: {
    typeId: 35,
    classifyId: 24,
    type: 'WFD',
    classify: 'listening',
    h1: 'Write from dictation',
    title:
      'You will hear a sentence. Type the sentence in the box below exactly as you hear it. Write as much of the sentence as you can. You will hear the sentence only once.'
  },
  42: {
    typeId: 42,
    classifyId: 26,
    type: 'MCM',
    classify: 'reading',
    h1: 'Multiple-choice, choose multiple answers',
    title:
      'Read the text and answer the question by selecting all the correct responses. More than one response is correct.'
  },
  43: {
    typeId: 43,
    classifyId: 26,
    type: 'MCS',
    classify: 'reading',
    h1: 'Multiple-choice, choose single answer',
    title:
      'Read the text and answer multiple-choice question by selecting the correct response. Only one response is correct.'
  },
  44: {
    typeId: 44,
    classifyId: 26,
    type: 'RO',
    classify: 'reading',
    h1: 'Re-order paragraphs',
    title:
      'The text boxes in the left panel have been placed in a random order. Restore the original order by dragging the text boxes from the left panel to the right panel.'
  },
  52: {
    typeId: 52,
    classifyId: 26,
    type: 'RFIB',
    classify: 'reading',
    h1: 'Fill in the blanks',
    title:
      'In the text below some words are missing. Drag words from the box below to the appropriate place in the text. To undo an answer choice, drag the word back to the box below the text.'
  },
  53: {
    typeId: 53,
    classifyId: 26,
    type: 'RWFIB',
    classify: 'reading',
    h1: 'R&W: Fill in the blanks',
    title:
      'Below is a text with blanks. Click on each blank, a list of choices will appear. Select the appropriate answer choice for each blank.'
  },
  45: {
    typeId: 45,
    classifyId: 27,
    type: 'SWT',
    classify: 'writing',
    h1: 'Summarize written text',
    title:
      'Read the passage below and summarize it using one sentence. Type your response in the box at the bottom of the screen. You have 10 minutes to finish this task. Your response will be judged on the quality of your writing and on how well your response presents the key points in the passage.'
  },
  46: {
    typeId: 46,
    classifyId: 27,
    type: 'WE',
    classify: 'writing',
    h1: 'Write essay',
    title:
      'You will have 20 minutes to plan, write and revise an essay about the topic below. Your response will be judged on how well you develop a position, organize your ideas, present supporting details, and control the elements of standard written English. You should write 200-300 words.'
  }
}

export const getTypeListSpeakAndRead = () =>
  [
    {
      name: 'Speaking',
      type: 'speaking',
      ch_name: i18n.locale === 'ch' ? '口语' : 'Speaking',
      classifyId: 25,
      list: [
        { clid: 36 },
        { clid: 37 },
        { clid: 38 },
        { clid: 39 },
        { clid: 40 }
      ]
    },

    {
      name: 'Reading',
      type: 'reading',
      ch_name: i18n.locale === 'ch' ? '阅读' : 'Reading',
      classifyId: 26,
      list: [
        { clid: 53 },
        { clid: 42 },
        { clid: 44 },
        { clid: 52 },
        { clid: 43 }
      ]
    }
  ].map((item) => {
    return {
      ...item,
      list: item.list.map((itemValue) => {
        return {
          ...TYPE_INFO[itemValue.clid],
          ...itemValue
        }
      })
    }
  })

export const getTypeListWriteAndListen = () =>
  [
    {
      name: 'Writing',
      type: 'writing',
      ch_name: i18n.locale === 'ch' ? '写作' : 'Writing',
      classifyId: 27,
      list: [{ clid: 45 }, { clid: 46 }]
    },
    {
      name: 'Listening',
      type: 'listening',
      ch_name: i18n.locale === 'ch' ? '听力' : 'Listening',
      classifyId: 24,
      list: [
        { clid: 28 },
        { clid: 29 },
        { clid: 30 },
        { clid: 31 },
        { clid: 32 },
        { clid: 33 },
        { clid: 34 },
        { clid: 35 }
      ]
    }
  ].map((item) => {
    return {
      ...item,
      list: item.list.map((itemValue) => {
        return {
          ...TYPE_INFO[itemValue.clid],
          ...itemValue
        }
      })
    }
  })

export const ratePercent = {
  RA: 49,
  RS: 62,
  DI: 65,
  RL: 88,
  ASQ: 95,
  SWT: 96,
  WE: 99,
  MCM: 6,
  MCS: 6,
  RO: 57,
  RFIB: 55,
  RWFIB: 66,
  SST: 90,
  FIB: 34,
  HCS: 6,
  SMW: 6,
  HIW: 5,
  WFD: 96
}

export const h1List69up = [
  'Write from dictation',
  'Summarize spoken text',
  'Fill in the blanks',
  'Describe image',
  'Re-tell lecture',
  'Answer short question',
  'Read aloud',
  'Repeat sentence',
  'Summarize written text',
  'Write essay',
  'Re-order paragraphs',
  'R&W: Fill in the blanks',
  'Fill in the blanks',
  'Highlight incorrect words'
]

export const h1List62to68 = [
  'Write from dictation',
  'Summarize spoken text',
  'Fill in the blanks',
  'Describe image',
  'Re-tell lecture',
  'Read aloud',
  'Repeat sentence',
  'Summarize written text',
  'Write essay',
  'Highlight incorrect words',
  'Fill in the blanks',
  'R&W: Fill in the blanks'
]

export const h1List50to61 = [
  'Write from dictation',
  'Summarize spoken text',
  'Describe image',
  'Re-tell lecture',
  'Read aloud',
  'Repeat sentence',
  'Summarize written text',
  'Write essay'
]

export const h1List36to49 = [
  'Write from dictation',
  'Summarize spoken text',
  'Describe image',
  'Read aloud',
  'Repeat sentence',
  'Summarize written text',
  'Write essay'
]

export const h1List35down = [
  'Write from dictation',
  'Summarize spoken text',
  'Describe image',
  'Read aloud',
  'Repeat sentence',
  'Summarize written text'
]
